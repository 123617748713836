import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper/core";

import $ from "jquery";

SwiperCore.use([Navigation, Thumbs, Pagination]);

import "swiper/swiper-bundle.css";
import "select2/dist/css/select2.css";
import "../scss/style.scss";

let select2 = require("select2");
$(window).on("load", function () {
  $("select").each(function () {
    $(this).select2();
  });
});

/// slider homepage
if (document.getElementById("swiperproperty")) {
  var swiper = new Swiper("#swiperproperty", {
    slidesPerView: "auto",
    spaceBetween: 25,
    slidesPerView: 1.2,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 1.3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  });
  //console.log(swiper);
}

if (document.getElementById("blog-swiper")) {
  var swiper = new Swiper("#blog-swiper", {
    slidesPerView: "auto",
    spaceBetween: 30,
    slidesPerView: 1.5,
    loop: true,
    breakpoints: {
      640: {
        slidesPerView: 1.5,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3.1,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  });
}

// scrolll
const navbar = document.getElementById("header");
const onScroll = () => {
  const scroll = document.documentElement.scrollTop;
  if (scroll > 100) {
    navbar.classList.add("scrolled");
  } else {
    navbar.classList.remove("scrolled");
  }
};
window.addEventListener("scroll", onScroll);

//nav
if (document.getElementById("show-nav-btn")) {
  document.getElementById("show-nav-btn").addEventListener("click", (e) => {
    const tgt = e.target;
    document.querySelector("body").classList.toggle("show-nav");
  });
}
if (document.getElementById("home")) {
  var bannerBot = document.getElementsByClassName("ban-wrap")[0];
  var floatsection = document.getElementsByClassName("float-section")[0];
  const onScroller = () => {
    if (bannerBot.getBoundingClientRect().bottom < 0) {
      floatsection.classList.add("show");
    } else {
      floatsection.classList.remove("show");
    }
  };
  window.addEventListener("scroll", onScroller);
}
